import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseConfig";
import {
  query,
  onSnapshot,
  collection,
  orderBy,
  limit,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "../../css/GameLogs.css";

const TopUserGameLogs = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, orderBy("gamesPlayed", "desc"), limit(100));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const users = [];
      snapshot.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
      setUsers(users);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="gameLog">
      <h3 className="logHeader">Top 100 Users By Games Played</h3>
      <div>
        {users.map((user) => (
          <div className="game" key={user.id + Math.random()}>
            <p>
              <strong>
                <Link to={`/user/${user.id}`}>{user.username}</Link>
              </strong>
              - Games played: {user.gamesPlayed}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopUserGameLogs;
