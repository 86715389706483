import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, collection } from 'firebase/firestore'
import Button from 'react-bootstrap/esm/Button'
import { deleteTeam } from '../../api/team'

const TeamsList = () => {
  const [teams, setTeams] = useState([])

  useEffect(() => {
    const q = query(collection(db, 'teams'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const teams = []
      querySnapshot.forEach((doc) => {
        teams.push({ ...doc.data(), id: doc.id })
      })
      setTeams(teams)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <div className="teamsContainer">
      {teams.map((team) => {
        return (
          <div
            key={team.name}
            style={{ flex: 1, minWidth: '25%', maxWidth: '25%' }}
          >
            <Card>
              <Card.Header as="h5">{team.name}</Card.Header>
              <Card.Body>
                <Card.Text>
                  <strong>Invite Codes</strong>
                </Card.Text>
                <ul>
                  {team.inviteCodes.map((code) => {
                    return <li key={code}>{code}</li>
                  })}
                </ul>
                <Card.Text>
                  <strong>Players</strong>: {team.players.length}
                </Card.Text>
                <Card.Text>
                  <strong>Created At</strong>:{' '}
                  {new Date(team.createdAt.seconds * 1000).toISOString()}
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you would like to delete this code?'
                      )
                    )
                      deleteTeam(team.id)
                  }}
                >
                  Delete
                </Button>
              </Card.Footer>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

export default TeamsList
