import { httpsCallable } from "firebase/functions";
import { functions, db } from "../services/firebaseConfig";
import { doc, deleteDoc } from "firebase/firestore";

const createAchievementFunc = httpsCallable(functions, "addAchievement");

export const createAchievement = (
  uid,
  name,
  shoeUid,
  description,
  trigger,
  gameUid,
  categoryUid,
  conditions
) => {
  return createAchievementFunc({
    uid: uid,
    categoryUid: categoryUid,
    conditions: conditions,
    description: description,
    gameUid: gameUid,
    trigger: trigger,
    unlocks: shoeUid,
    name: name,
  });
};

export const deleteAchievement = async (uid) => {
  return deleteDoc(doc(db, "achievements", uid));
};
