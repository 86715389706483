import { useEffect, useState } from "react";
import { db } from "../services/firebaseConfig";
import { query, onSnapshot, collection, doc } from "firebase/firestore";

const useShoes = () => {
  const [items, setItems] = useState([]);
  const [starterItems, setStarterItems] = useState([]);
  const [unusedItems, setUnusedItems] = useState([]);
  const [inviteCodeItems, setInviteCodeItems] = useState([]);

  useEffect(() => {
    const itemsQ = query(collection(db, "items"));
    const unsubscribeAllItems = onSnapshot(itemsQ, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setItems(items);
    });
    const starterQ = query(doc(db, "starterShoes", "root"));
    const unsubscribeStarterItems = onSnapshot(starterQ, (querySnapshot) => {
      const items = [];
      querySnapshot.data().shoes.forEach((doc) => {
        items.push(doc);
      });
      setStarterItems(items);
    });

    return () => {
      unsubscribeAllItems();
      unsubscribeStarterItems();
    };
  }, []);

  useEffect(() => {
    setUnusedItems(
      items.filter(
        (item) =>
          !starterItems.map((sItem) => sItem.uid).includes(item.uid) &&
          !item.unlockedBy &&
          !item.inviteCode
      )
    );
    setInviteCodeItems(items.filter((item) => item.inviteCode));
  }, [items, starterItems]);

  return { items, starterItems, unusedItems, inviteCodeItems };
};

export default useShoes;
