import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { createTeam } from "../../api/team";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Multiselect from "multiselect-react-dropdown";

const CreateTeamForm = ({ codes }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteCodes, setInviteCodes] = useState([]);
  const [message, setMessage] = useState();
  const [useableCodes, setUseableCodes] = useState([]);

  useEffect(() => {
    if (!codes) return;
    const newCodes = [];
    for (const code of codes) {
      if (!code.team) newCodes.push(code.code);
    }
    setUseableCodes(newCodes);
  }, [codes]);

  const onTeamPressed = () => {
    if (loading) return;

    setLoading(true);
    createTeam(name, inviteCodes)
      .then(() => setMessage({ text: "Created Team!", type: "success" }))
      .catch((error) => setMessage({ text: error.message, type: "danger" }))
      .finally(() => {
        setLoading(false);
        setName("");
      });
  };

  return (
    <Card>
      <Card.Header as="h5">Create Team</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Team Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the team name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Invite Codes</Form.Label>
            <Multiselect
              options={useableCodes}
              onSelect={(list, item) => {
                const codes = inviteCodes;
                codes.push(item);
                setInviteCodes(codes);
              }}
              onRemove={(list, item) => {
                const codes = inviteCodes;
                codes.splice(codes.indexOf(item), 1);
                setInviteCodes(codes);
              }}
              isObject={false}
            />
          </Form.Group>
          <br />
          <Form.Group className="buttonContainer">
            <Button variant="success" onClick={onTeamPressed}>
              Create Team
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {loading && <Spinner animation="border" />}
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  );
};

export default CreateTeamForm;
