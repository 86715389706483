import { db } from "../services/firebaseConfig";
import { getDocs, collection, query, where } from "firebase/firestore";

export const getDailyGamesPlayed = async () => {
  const queryDate = new Date();
  queryDate.setDate(queryDate.getDate() - 1);
  const q = query(collection(db, "score"), where("createdAt", ">=", queryDate));
  const result = await getDocs(q);
  const scores = [];
  result.forEach((doc) => scores.push(doc.data()));

  return scores;
};

export const getWeeklyGamesPlayed = async () => {
  const queryDate = new Date();
  queryDate.setDate(queryDate.getDate() - 7);
  const q = query(collection(db, "score"), where("createdAt", ">=", queryDate));
  const result = await getDocs(q);

  const scores = [];
  result.forEach((doc) => scores.push(doc.data()));

  return scores;
};
