import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import DateTimePicker from 'react-datetime-picker'
import Alert from 'react-bootstrap/Alert'
import { createSeason } from '../../api/seasons'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import moment from 'moment'

const CreateSeasonForm = () => {
  const [name, setName] = useState('')
  const [seasonEnd, setSeasonEnd] = useState(
    moment(new Date()).format('YYYY-MM-DDTHH:mm')
  )
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()

  const onSeasonPressed = () => {
    if (loading) return

    setLoading(true)
    createSeason(name, seasonEnd)
      .then(() => setMessage({ text: 'Created Season!', type: 'success' }))
      .catch((error) => setMessage({ text: error.message, type: 'danger' }))
      .finally(() => {
        setLoading(false)
        setName('')
        setSeasonEnd(new Date())
      })
  }

  return (
    <Card>
      <Card.Header as="h5">Create Season</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Season Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the season name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <DateTimePicker onChange={setSeasonEnd} value={seasonEnd} />
          </Form.Group>
          <Form.Group className="buttonContainer">
            <Button variant="success" onClick={onSeasonPressed}>
              Create Season
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {loading && <Spinner animation="border" />}
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  )
}

export default CreateSeasonForm
