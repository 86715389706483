import React from 'react'
import Modal from 'react-bootstrap/Modal'
import '../../css/ShoeImageSelectorModal.css'

const ShoeImageSelectorModal = ({ onClose, shoes, onSelect }) => {
  return (
    <Modal
      show
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Shoe
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scrolLView">
          <div className="flex flex-wrap">
            {shoes.map((item) => (
              <img
                key={item.name}
                src={item.image}
                alt={'shoe'}
                className="smallShoeImage flex-shrink-0 m-4"
                onClick={() => onSelect(item)}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ShoeImageSelectorModal
