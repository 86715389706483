// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfigProd = {
  apiKey: "AIzaSyCCE-EnEifkk7XI12HRcfXOm-P2cSNKVkE",
  authDomain: "velocity-v1-production.firebaseapp.com",
  projectId: "velocity-v1-production",
  storageBucket: "velocity-v1-production.appspot.com",
  messagingSenderId: "546454652847",
  appId: "1:546454652847:web:5fbe1ee9eb49e016a6115a",
  measurementId: "G-59QVEDJW4R",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyDZD2fhuNsNozDOPIUk9bNx7p98P_WEaL8",
  authDomain: "velocity-v1.firebaseapp.com",
  databaseURL: "https://velocity-v1-default-rtdb.firebaseio.com",
  projectId: "velocity-v1",
  storageBucket: "velocity-v1.appspot.com",
  messagingSenderId: "184466177701",
  appId: "1:184466177701:web:a46d793d354ebc4ea8574c",
  measurementId: "G-3938K4WBLQ",
};

const firebaseConfig =
  process.env.REACT_APP_DEVELOPMENT === "false"
    ? firebaseConfigProd
    : firebaseConfigDev;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);

const provider = new GoogleAuthProvider();

export const googleSignIn = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
    })
    .catch((error) => console.log(error));
};

export const logout = () => {
  return signOut(auth).then((result) => {
    console.log(result);
  });
};
