import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, doc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import GameStats from './GameStats'
import Performance from './Performance'
import Tournament from './Tournament'
import '../../css/User.css'
import ItemCard from '../util/ItemCard'
import Button from 'react-bootstrap/Button'
import ReactJson from 'react-json-view'
import GameLogs from './GameLogs'

const User = () => {
  const { userId } = useParams()
  const [userData, setUserData] = useState({})
  const [viewJson, setViewJson] = useState(false)

  useEffect(() => {
    const q = query(doc(db, 'users', userId))
    const unsubscribe = onSnapshot(q, (doc) => {
      setUserData(doc.data())
    })
    return () => {
      unsubscribe()
    }
  }, [userId])

  return (
    <div className="space-y-8 px-4">
      <div>
        <div>
          <div className="flex space-x-8 items-center mb-4">
            <img
              src={userData.photoURL}
              className="w-24 rounded-full"
              alt="Profile"
            />
            <div>
              <p className="font-bold m-0 text-2xl">{userData.username}</p>
              <p className="m-0 text-gray-600">
                {userData.height}cm - {userData.weight}kg -{' '}
                {userData.dateOfBirth}
              </p>
            </div>
          </div>
          <div className="grid xl:grid-cols-2 gap-4">
            <div>
              <div className="bg-white rounded-md p-4 w-full shadow-sm">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="text-sm">{userData.displayName}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">
                    Sport - Position
                  </dt>
                  <dd className="text-sm m-0">
                    {userData.sport} - {userData.position}
                  </dd>
                </div>
              </div>

              {/*<Button onClick={() => setViewJson(!viewJson)}>View JSON</Button>*/}
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-1 lg:grid-cols-3 gap-4">
              <div className="bg-white rounded-md p-4 text-center shadow-sm flex flex-col justify-center">
                <h4 className="mb-1">{userData.followers}</h4>
                <p className="m-0 text-sm text-gray-500 font-medium">
                  Followers
                </p>
              </div>
              <div className="bg-white rounded-md p-4 text-center shadow-sm flex flex-col justify-center">
                <h4 className="mb-1">{userData.following}</h4>
                <p className="m-0 text-sm text-gray-500 font-medium">
                  Following
                </p>
              </div>
              <div className="bg-white rounded-md p-4 text-center shadow-sm flex flex-col justify-center">
                <h4 className="mb-1">{userData?.gamesPlayed}</h4>
                <p className="m-0 text-sm text-gray-500 font-medium">
                  Games Played
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 flex flex-col">
        <h4 className="text-lg mb-2">Performance Stats</h4>
        <Performance userId={userId} />
      </div>
      <div>
        <h4 className="text-lg font-medium text-gray-900">Game Stats</h4>
        <GameStats userId={userId} />
      </div>
      <div className="col-span-3 flex flex-col">
        <h4 className="text-lg mb-2">Game Logs (30 Most Recent)</h4>
        <GameLogs userId={userId} />
      </div>
      <div>
        <h4 className="text-lg font-medium text-gray-900 leading-0 mb-1">
          Tournament Stats
        </h4>
        <Tournament userId={userId} />
      </div>
      <div>
        <h4 className="text-lg font-medium text-gray-900">Equipped Items</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
          {userData.equippedItems?.map((item) => (
            <ItemCard
              item={item}
              showDelete={false}
              showSelectStarterItem={false}
              showRemoveStarterItem={false}
              key={item.uid}
            />
          ))}
        </div>
      </div>

      <div>{viewJson && <ReactJson src={userData} />}</div>
    </div>
  )
}

export default User
