import { httpsCallable } from "firebase/functions";
import { functions, db, auth } from "../services/firebaseConfig";
import { getDoc, doc } from "firebase/firestore";

const setAuthClaims = httpsCallable(functions, "addRoleClaims");

export const getUserProfile = (uid) => {
  const docRef = doc(db, "users", uid);
  return getDoc(docRef, uid).then((snapshot) => {
    if (snapshot.exists) return snapshot.data();
  });
};

export const addAuthClaims = () => {
  setAuthClaims()
    .then((result) => {
      auth.currentUser.getIdToken(true);
    })
    .catch((error) => console.log(error));
};
