import algoliasearch from "algoliasearch";
const DEV_ALGOLIA_APP_ID = "61IWCLWM1K";
const DEV_ALGOLIA_API_KEY = "aee6a0ee3b5554dff535e63373eec4df";

const ALGOLIA_APP_ID = "U2H076ZX7P";
const ALGOLIA_API_KEY = "32555e00f41bddcce25eaf42e1e8010c";

export const client =
  process.env.REACT_APP_DEVELOPMENT !== "false"
    ? algoliasearch(DEV_ALGOLIA_APP_ID, DEV_ALGOLIA_API_KEY)
    : algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
export const index = client.initIndex("usersSearchIndex");
