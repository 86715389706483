import React, { useState } from "react";
import "../../css/InviteCodeTable.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  deleteInviteCode,
  increaseInviteCodeQuantity,
} from "../../api/inviteCodes";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

const InviteCodeTable = ({ codes }) => {
  const [loading, setLoading] = useState(false);

  const updateQuantity = (codeUid, value) => {
    if (loading) return;
    setLoading(true);
    increaseInviteCodeQuantity(codeUid, value)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <td>
            <strong>Quantity</strong>
            {loading && <Spinner animation="border" />}
          </td>
          <td>
            <strong>Code</strong>
          </td>
          <td>
            <strong>Unlocks</strong>
          </td>
          <td>
            <strong>Associated User</strong>
          </td>
          <td>
            <strong># Used By</strong>
          </td>
          <td>
            <strong>Roles Granted</strong>
          </td>
          <td>
            <strong>Delete</strong>
          </td>
        </tr>
      </thead>
      <tbody>
        {codes &&
          codes.map((code) => (
            <tr key={code.code}>
              <td>
                <div className="quantity">
                  <Form.Control
                    type="number"
                    min={0}
                    defaultValue={code.quantity}
                    id={code.code}
                    className="quantity-input"
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      const value = document.getElementById(code.code).value;
                      updateQuantity(code.code, value);
                    }}
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                </div>
              </td>
              <td>{code.code}</td>
              <td>{code.unlocksItems ? code.unlocksItems[0] : "N/A"}</td>
              <td style={{ fontSize: 10 }}>
                <Link to={`/user/${code.user?.id}`}>
                  {code.user ? code.user.id : ""}
                </Link>
              </td>
              <td>{code.usedBy ? code.usedBy.length : 0}</td>
              {code.rolesGranted ? (
                <td>{code.rolesGranted.join(" | ")}</td>
              ) : (
                <td></td>
              )}
              <td>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you'd like to delete this invite code?"
                      )
                    ) {
                      deleteInviteCode(code.code);
                    }
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default InviteCodeTable;
