import React, { useState } from "react";
import { loadItem } from "../../assets";
import "../../css/AchievementList.css";
import Button from "react-bootstrap/Button";
import { deleteAchievement } from "../../api/achievements";
import Spinner from "react-bootstrap/Spinner";

const AchievementList = ({ shoes, achievements }) => {
  const [loading, setLoading] = useState(false);

  const getShoesDict = (shoes) => {
    const dict = {};

    for (const shoe of shoes) {
      dict[shoe.uid] = shoe;
    }

    return dict;
  };

  const shoeLookup = getShoesDict(shoes);

  const deleteAchievementUi = (achievementUid) => {
    if (loading) return;
    if (
      !window.confirm(
        "Are you sure you want to delete this achievement? This is an irreversible operation."
      )
    )
      return;
    setLoading(true);
    deleteAchievement(achievementUid)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        window.alert(error.message);
      });
  };

  return (
    <div>
      <h2 className="achievementHeader">Achievements</h2>
      {loading && <Spinner animation="border" />}
      <div className="achievementContainer">
        {achievements.map((achievement) => (
          <div className="shoeCard card " key={achievement.uid}>
            <div className="card-body">
              <h5 className="card-title">
                <strong>{achievement.name}</strong>
              </h5>
              <div className="shoeImageWrapper">
                <img
                  className="shoeImage"
                  src={
                    shoeLookup[achievement.unlocks]
                      ? loadItem(shoeLookup[achievement.unlocks].image)
                      : ""
                  }
                  alt="Shoe"
                />
              </div>
              <br />
              <p className="card-text">
                <strong>Unlocks Shoe UID</strong>: {achievement.unlocks}
              </p>
              <p className="card-text">
                <strong>Achievement UID</strong>: {achievement.uid}
              </p>
              <p className="card-text">
                <strong>Description</strong>: {achievement.description}
              </p>
              <p className="card-text">
                <strong>Conditions</strong>
              </p>
              <ul>
                {Object.values(achievement.conditions).map((condition) => {
                  return (
                    <li key={Math.random()}>{condition.map((part) => part)}</li>
                  );
                })}
              </ul>
              <p className="card-text">
                <strong>GameUid</strong>: {achievement.gameUid}
              </p>
              <p className="card-text">
                <strong>CategoryUid</strong>: {achievement.categoryUid}
              </p>

              <div className="buttonContainer">
                <Button
                  className="btn btn-danger"
                  onClick={() => deleteAchievementUi(achievement.uid)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AchievementList;
