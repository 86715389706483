import React, { useState, useEffect } from "react";
import { getNewDailyUsers, getTotalUsers } from "../../api/users";
import { Link } from "react-router-dom";
import "../../css/Users.css";
import { index } from "../../services/algolia";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const getDateDifference = (date) => {
  // get total seconds between the times
  var delta = Math.abs(date - new Date()) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = Math.round(delta % 60); // in theory the modulus is not required
  return { minutes, seconds, hours };
};

const HITS_PER_PAGE = 20;

const Users = () => {
  const [userCount, setUserCount] = useState(0);
  const [newUsers, setNewUsers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    getNewDailyUsers().then((users) => {
      const results = [];
      users.forEach((doc) => results.push({ ...doc.data(), id: doc.id }));
      setNewUsers(results);
    });
    getTotalUsers().then((count) => setUserCount(count));
  }, []);

  const search = async (user, paginate = false) => {
    let pageNumber = 0;
    if (paginate) {
      pageNumber = page + 1;
      setPage(page + 1);
    } else {
      setPage(0);
    }
    const results = [];
    await index
      .search(user, {
        attributesToRetrieve: [
          "displayName",
          "photoURL",
          "followers",
          "username",
          "dateOfBirth",
          "height",
          "weight",
          "equippedItems",
          "items",
          "sport",
          "position",
          "bio",
          "objectID",
          "gamesPlayed",
        ],
        hitsPerPage: HITS_PER_PAGE,
        page: pageNumber,
      })
      .then(({ hits, nbPages }) => {
        setAllLoaded(nbPages === pageNumber + 1);
        hits.forEach((doc) => {
          if (doc && doc.username)
            results.push({
              ...doc,
            });
        });
      });
    if (paginate) {
      setSearchResults([...results, ...searchResults]);
    } else {
      setSearchResults(results);
    }
  };

  return (
    <div className="userScreen">
      <div className="userStats">
        <div className="userStatContainer">
          <h1>{newUsers.length}</h1>
          <h3>New Users (24 Hours)</h3>
        </div>
        <div className="userStatContainer">
          <h1>{userCount}</h1>
          <h3>Total Users</h3>
        </div>
      </div>
      <div className="userLogs">
        <div className="heading">
          <h3>Users signed up in last 24 hours.</h3>
        </div>
        <div className="scrollableTable">
          {newUsers.map((user) => {
            const timeDiff = getDateDifference(
              new Date(user.created.seconds * 1000)
            );
            return (
              <div key={user.id}>
                <strong>
                  <Link to={`/user/${user.id}`}>{user.username}</Link>
                </strong>{" "}
                created an account {timeDiff.hours} hours, {timeDiff.minutes}{" "}
                minutes and {timeDiff.seconds} seconds ago
              </div>
            );
          })}
        </div>
      </div>
      <div className="search">
        <Form.Group>
          <Form.Label as="h4">Search For User</Form.Label>
          <div className="searchForm">
            <Form.Control
              type="text"
              placeholder="Search for a username"
              onChange={(e) => {
                //if (e.target.value.length === 0) setSearchResults([]);
                if (
                  e.target.value.length > 2 ||
                  e.target.value.length < username.length
                )
                  search(e.target.value);
                setUsername(e.target.value);
              }}
              value={username}
            />
          </div>
        </Form.Group>
        <div className="searchResults">
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>
                  <strong>User Data</strong>
                </td>
                <td>
                  <strong>ID</strong>
                </td>
                <td>
                  <strong>Sport</strong>
                </td>
                <td>
                  <strong>Position</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result) => (
                <tr key={result.username}>
                  <td>
                    <img
                      src={result.photoURL}
                      className="profilePhoto"
                      alt="Profile"
                    />
                    <strong>
                      <Link to={`/user/${result.objectID}`}>
                        {result.username}
                      </Link>
                    </strong>
                  </td>
                  <td>{result.objectID}</td>
                  <td>{result.sport}</td>
                  <td>{result.position}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!allLoaded && searchResults.length > 0 && (
            <div>
              <Button
                variant="success"
                onClick={() => {
                  search(username, true);
                }}
              >
                Load More...
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
