import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { createShoe } from '../api/shoes'
import { loadItem, images } from '../assets'
import ShoeImageSelectorModal from './util/ShoeImageSelectorModal'

const RarityLevels = {
  BEGINNER: 1,
  AMATEUR: 2,
  ROOKIE: 3,
  SEMIPRO: 4,
  PRO: 5,
  ALLSTAR: 6,
}

const RarityUids = {
  BEGINNER: 'beginner',
  AMATEUR: 'amateur',
  ROOKIE: 'rookie',
  SEMIPRO: 'semipro',
  PRO: 'pro',
  ALLSTAR: 'allstar',
}

const getRarityLevelFromUid = {
  [RarityUids.BEGINNER]: RarityLevels.BEGINNER,
  [RarityUids.AMATEUR]: RarityLevels.AMATEUR,
  [RarityUids.ROOKIE]: RarityLevels.ROOKIE,
  [RarityUids.SEMIPRO]: RarityLevels.SEMIPRO,
  [RarityUids.PRO]: RarityLevels.PRO,
  [RarityUids.ALLSTAR]: RarityLevels.ALLSTAR,
}

export default function CreateShoeSlideOver(props) {
  const [name, setName] = useState('')
  const [uid, setUid] = useState('')
  const [image, setImage] = useState()
  const [rarity, setRarity] = useState('beginner')
  const [description, setDescription] = useState('')
  const [message, setMessage] = useState('')
  const [showImageSelector, setShowImageSelector] = useState(false)
  const [loading, setLoading] = useState(false)

  const rarities = Object.values(RarityUids)
  const usedImageNames = props.existingItems.map((item) => item.image)
  const availableShoeImages = Object.keys(images)
    .filter((imageName) => !usedImageNames.includes(imageName))
    .map((name) => ({ name: name, image: loadItem(name) }))

  const createShoePressed = () => {
    if (loading) return
    setLoading(true)
    createShoe(uid, name, image.name, description, rarity)
      .then(() => {
        setLoading(false)
        setName('')
        setUid('')
        setRarity('beginner')
        setImage(undefined)
        setDescription('')
      })
      .catch((error) => {
        setMessage({ text: error.message, type: 'danger' })
        setLoading(false)
      })
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.close}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        {showImageSelector && (
          <ShoeImageSelectorModal
            onClose={() => setShowImageSelector(false)}
            shoes={availableShoeImages}
            onSelect={(item) => {
              setImage(item)
              setShowImageSelector(false)
            }}
          />
        )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={(event) => {
                      event.preventDefault()
                      createShoePressed()
                      props.close()
                    }}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Create shoe
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Get started by filling in the information below to
                              create a new shoe.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => {
                                props.close()
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Shoe Image */}
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="shoeImage"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Image
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <div className="flex items-center space-x-6">
                              <div className="shrink-0">
                                {image && (
                                  <img
                                    className="h-16 w-16 object-fit"
                                    src={
                                      image && image.image ? image.image : ''
                                    }
                                    alt="Current shoe photo"
                                  />
                                )}

                                {!image && (
                                  <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
                                )}
                              </div>
                              <label className="block">
                                <span className="sr-only">
                                  Choose shoe photo
                                </span>
                                <button
                                  onClick={(event) => {
                                    event.preventDefault()
                                    setShowImageSelector(true)
                                  }}
                                  className="block w-full text-sm text-slate-500 mr-4 py-2 px-4 rounded-full border-0 text-sm font-semibold bg-orange-50 text-orange-700 hover:bg-orange-100"
                                >
                                  Select
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Shoe UID */}
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="shoeUID"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Shoe UID
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="shoeUID"
                              id="shoeUID"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                              placeholder="Enter a shoe unique identifier (no spaces)"
                              value={uid}
                              onChange={(e) => setUid(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* Shoe Name */}
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="shoeName"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Shoe Name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="shoeName"
                              id="shoeName"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                              placeholder="Enter a shoe name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* Shoe Rarity */}
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="shoeName"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Rarity
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                              value={rarity}
                              onChange={(e) => setRarity(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                            >
                              {rarities.map((r) => (
                                <option key={r} value={r}>
                                  {r +
                                    ' - ' +
                                    getRarityLevelFromUid[r].toString()}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Shoe description */}
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="shoe-description"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="shoe-description"
                              name="shoe-description"
                              rows={3}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                          onClick={() => {
                            props.close()
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                          Create Shoe
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
