import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import {
  query,
  onSnapshot,
  collection,
  where,
  orderBy,
} from 'firebase/firestore'
import { getCurrentSeason, deleteSeason } from '../../api/seasons'

import useShoes from '../../hooks/useShoes'
import { loadItem } from '../../assets'
import RewardCard from '../util/RewardCard'
import CreateSeasonRewardForm from '../forms/CreateSeasonRewardForm'
import CreateSeasonForm from '../forms/CreateSeasonForm'
import CreateSeasonSlideover from '../CreateSeasonSlideover'

import moment from 'moment'

const Seasons = () => {
  const itemData = useShoes()

  const [seasons, setSeasons] = useState([])
  const [seasonRewards, setSeasonRewards] = useState([])
  const [currentSeason, setCurrentSeason] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const seasonsQ = query(collection(db, 'seasons'))
    const unsubscribeSeasons = onSnapshot(seasonsQ, (querySnapshot) => {
      const seasons = []
      querySnapshot.forEach((doc) => {
        seasons.push(doc.data())
      })
      setSeasons(seasons.sort((a, b) => a.seasonNumber - b.seasonNumber))
    })
    getCurrentSeason().then((season) => setCurrentSeason(season))

    return unsubscribeSeasons
  }, [])

  useEffect(() => {
    if (currentSeason <= 0) return

    const seasonRewardsQ = query(
      collection(db, 'seasonRewards'),
      where('season', '>=', currentSeason),
      orderBy('season')
    )
    const unsubscribeSeasonRewards = onSnapshot(
      seasonRewardsQ,
      (querySnapshot) => {
        const rewards = []
        querySnapshot.forEach((doc) => {
          rewards.push(doc.data())
        })
        setSeasonRewards(rewards)
      }
    )

    return unsubscribeSeasonRewards
  }, [currentSeason])

  const deleteSeasonClicked = () => {
    if (loading) return

    setLoading(true)
    deleteSeason()
      .catch((error) => alert(error.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const getShoesDict = (shoes) => {
    const dict = {}

    for (const shoe of shoes) {
      dict[shoe.uid] = shoe
    }

    return dict
  }

  const shoeLookup = getShoesDict(itemData.items)
  const [slideoverOpen, setSlideoverOpen] = useState(false)

  return (
    <div className="container-fluid">
      <CreateSeasonSlideover
        open={slideoverOpen}
        close={() => {
          setSlideoverOpen(false)
        }}
      />

      <div className="flex justify-between items-center mb-3">
        <h2 className="font-mono uppercase font-bold text-gray-500 text-2xl m-0">
          Current Season = {currentSeason}
        </h2>
        <button
          onClick={() => setSlideoverOpen(!slideoverOpen)}
          className="mt-2 w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 text-sm leading-6 font-medium rounded-full transition duration-75 ease-in-out md:py-4 md:px-10  tracking-wide focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow bg-gradient-to-br from-cyan-500 via-blue-600 to-blue-600 !text-blue-100 hover:!text-white"
        >
          Create Season
        </button>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {seasons.map((season, idx) => {
          if (!season.seasonNumber) {
            return <></>
          }

          return (
            <div
              key={season.name}
              className="bg-white shadow-sm rounded-lg p-4 relative"
            >
              <div className="text-gray-600 text-sm text-medium">
                {season.name}
              </div>

              <p className="m-0 text-sm">
                Ends{' '}
                <span className="font-bold">
                  {moment(
                    new Date(season.endsAt.seconds * 1000).toISOString()
                  ).format('MMMM Do YYYY, h:mm:ss a')}
                </span>
              </p>
              {idx === seasons.length - 2 && seasons.length > 2 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 absolute top-0 right-0 m-2 text-gray-400 hover:text-gray-900 cursor-pointer"
                  onClick={deleteSeasonClicked}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
          )
        })}
      </div>

      <CreateSeasonForm />
      <br />
      <h3 style={{ textAlign: 'center' }}>Current/Future Season Rewards</h3>
      <div className="shoeContainer">
        {seasonRewards.map((reward, index) => {
          return (
            <RewardCard
              key={reward.uid}
              reward={reward}
              image={
                shoeLookup[reward.unlocks]
                  ? loadItem(shoeLookup[reward.unlocks].image)
                  : ''
              }
            />
          )
        })}
      </div>
      <br />
      <CreateSeasonRewardForm
        currentSeason={currentSeason}
        shoes={itemData.unusedItems}
      />
    </div>
  )
}

export default Seasons
