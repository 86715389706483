import { httpsCallable } from "firebase/functions";
import { doc, deleteDoc } from "firebase/firestore";
import { functions, db } from "../services/firebaseConfig";

const createTeamFunc = httpsCallable(functions, "createTeam");

export const createTeam = (name, inviteCodes) => {
  return createTeamFunc({
    name: name,
    inviteCodes: inviteCodes,
  });
};

export const deleteTeam = async (uid) => {
  return deleteDoc(doc(db, "teams", uid));
};
