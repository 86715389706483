import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Typeahead } from "react-bootstrap-typeahead";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import ShoeImageSelectorModal from "components/util/ShoeImageSelectorModal";

import { getUserIdByUsername, generateCode } from "api/inviteCodes";
import { loadItem } from "assets";
import placeholderImage from "assets/placeholderImage.jpeg";
import useShoes from "hooks/useShoes";
import { index } from "services/algolia";

import "css/CreateInviteCodeForm.css";

const CreateInviteCodeForm = () => {
  const roles = [
    { name: "beta_access", value: "beta_access" },
    { name: "admin", value: "admin" },
  ];

  const itemData = useShoes();
  const [unusedItems, setUnusedItems] = useState([]);
  const [message, setMessage] = useState();
  const [selectedRole, setSelectedRole] = useState("beta_access");
  const [results, setResults] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [code, setCode] = useState();
  const [quantity, setQuantity] = useState(3);

  const [item, setItem] = useState();
  const [showImageSelector, setShowImageSelector] = useState(false);

  useEffect(() => {
    setUnusedItems(
      itemData.unusedItems.map((item) => ({
        ...item,
        image: loadItem(item.image),
      }))
    );
  }, [itemData.unusedItems]);

  const search = async (user) => {
    const searchResults = [];
    await index
      .search(user, {
        attributesToRetrieve: [
          "displayName",
          "photoURL",
          "followers",
          "username",
        ],
      })
      .then(({ hits }) =>
        hits.forEach((doc) => {
          if (doc && doc.username)
            searchResults.push({
              displayName: doc.displayName,
              username: doc.username,
              followers: doc.followers,
              photoURL: doc.photoURL,
            });
        })
      );
    setResults(searchResults);
  };

  const createInviteCodeFunc = async () => {
    let id;
    try {
      id = await getUserIdByUsername(selectedUsername);
      generateCode(id, code, quantity, [selectedRole], item ? [item.uid] : null)
        .then((data) => setMessage({ text: "Code created!", type: "success" }))
        .catch((error) => setMessage({ text: error.message, type: "danger" }));
    } catch (error) {
      setMessage({ text: error.message, type: "danger" });
      return;
    } finally {
      setItem(null);
      setQuantity(3);
      setCode("");
      setSelectedUsername("");
    }
  };

  return (
    <Card className="card">
      {showImageSelector && (
        <ShoeImageSelectorModal
          onClose={() => setShowImageSelector(false)}
          shoes={unusedItems}
          onSelect={(item) => {
            setItem(item);
            setShowImageSelector(false);
          }}
        />
      )}
      <Card.Header as="h5">Create Invite Code</Card.Header>
      <Card.Body className="content">
        <Form className="form">
          <div className="selectImage">
            {item ? (
              <Form.Group className="selectImage">
                <Button onClick={() => setItem(null)} variant="danger">
                  Remove
                </Button>
                <img
                  src={item && item.image ? item.image : ""}
                  alt="shoe"
                  className="imageSelected"
                />
                <p>UID: {item ? item.uid : ""}</p>
              </Form.Group>
            ) : (
              <img
                src={placeholderImage}
                alt="shoe"
                className="smallShoeImage"
                onClick={() => setShowImageSelector(true)}
              />
            )}
            <Form.Group>
              <Button onClick={() => setShowImageSelector(true)}>
                Select an Image
              </Button>
            </Form.Group>
          </div>

          <div className="formInput">
            <Form.Group className="item">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Default = 3"
                min={1}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="item">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Leave blank for random"
                min={1}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Role Granted</Form.Label>
              <Form.Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                {roles.map((role) => (
                  <option key={role.name} value={role.value}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="item">
              <Form.Label>Select Associated User</Form.Label>
              <Typeahead
                id="searchUser"
                onInputChange={(selected) => {
                  search(selected);
                  setSelectedUsername("");
                }}
                onChange={(selected) => {
                  if (selected.length > 0)
                    setSelectedUsername(selected[0].username);
                }}
                labelKey="username"
                options={results}
              />
            </Form.Group>

            <div className="createInviteCodeButton">
              <Button
                variant="success"
                onClick={createInviteCodeFunc}
                disabled={quantity <= 0 || selectedUsername?.length <= 2}
              >
                Create Invite Code
              </Button>
            </div>
          </div>
        </Form>
      </Card.Body>
      <Card.Footer>
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  );
};

export default CreateInviteCodeForm;
