import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseConfig";
import { query, onSnapshot, collection } from "firebase/firestore";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { markReportViewed } from "../../api/report";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

const Report = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const q = query(collection(db, "reports"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const reports = [];
      querySnapshot.forEach((doc) => {
        reports.push({
          ...doc.data(),
          createdAt: doc.data().createdAt.seconds * 1000,
          id: doc.id,
        });
      });
      reports.sort(
        (a, b) =>
          new Date(b.createdAt.seconds).getTime() -
          new Date(a.createdAt).getTime()
      );
      setReports(reports);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const markViewed = (itemId, checked) => {
    if (loading) return;
    setLoading(true);
    markReportViewed(itemId, checked)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="margin">
        <h3 className="inviteCodeHeader">User Reports</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td style={{ color: "green" }}>
                <strong>Reporting User Id</strong>
              </td>
              <td style={{ color: "red" }}>
                <strong>Reported User Id</strong>
              </td>
              <td>
                <strong>Created At</strong>
              </td>
              <td>
                <strong>Reason</strong>
              </td>
              <td>
                <strong>Description</strong>
              </td>
              <td>
                <strong>Viewed</strong>
                {loading && <Spinner animation="border" />}
              </td>
            </tr>
          </thead>
          <tbody>
            {reports.map((item) => (
              <tr key={item.createdAt}>
                <td>
                  <Link
                    to={`/user/${item.createdBy.id}`}
                    style={{ color: "green" }}
                  >
                    {item.createdBy.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/user/${item.reportedUser.id}`}
                    style={{ color: "red" }}
                  >
                    {item.reportedUser.id}
                  </Link>
                </td>
                <td>{new Date(item.createdAt).toDateString()}</td>
                <td>{item.reason}</td>
                <td>{item.description}</td>
                <td>
                  <Form>
                    <Form.Check
                      type="switch"
                      checked={item.viewed !== undefined ? item.viewed : false}
                      onChange={(e) => {
                        markViewed(item.id, e.target.checked);
                      }}
                    />
                  </Form>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Report;
