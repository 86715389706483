import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { deleteSeasonReward } from "../../api/seasons";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const RewardCard = ({ reward, image }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Card className="shoeCard">
      <Card.Header>{reward.name}</Card.Header>
      <Card.Body>
        <div className="shoeImageWrapper">
          <img className="shoeImage" src={image} alt="Shoe" />
        </div>
        <p className="card-text">
          <strong>Unlocks Shoe UID</strong>: {reward.unlocks}
        </p>
        <p className="card-text">
          <strong>Reward UID</strong>: {reward.uid}
        </p>
        <p className="card-text">
          <strong>Description</strong>: {reward.description}
        </p>
        <p className="card-text">
          <strong>Rank Required</strong>: {reward.rankRequired}
        </p>
        <p className="card-text">
          <strong>Season</strong>: {reward.season}
        </p>
        <Button
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you want to delete this reward? This is an irreversible operation."
              )
            ) {
              setLoading(true);
              deleteSeasonReward(reward.uid).finally(() => setLoading(false));
            }
          }}
          variant="danger"
        >
          Delete {loading && <Spinner animation="border" />}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default RewardCard;
