import { googleSignIn } from "../../services/firebaseConfig";
import icon from "../../assets/Velocity_Icon_RGB_300ppi.png";
import "../../css/Login.css";

const Login = () => {
  return (
    <div className="container">
      <img src={icon} style={{ width: "20%" }} alt="" />
      <h1>Velocity Admin Panel</h1>
      <div className="g-sign-in-button" onClick={googleSignIn}>
        <div className="content-wrapper">
          <div className="logo-wrapper">
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt=""
            />
          </div>
          <span className="text-container">
            <span>Sign in with Google</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
