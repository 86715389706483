import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import '../../css/CreateShoe.css'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { loadItem, images } from '../../assets'
import ShoeImageSelectorModal from '../util/ShoeImageSelectorModal'
import { createShoe } from '../../api/shoes'

const RarityLevels = {
  BEGINNER: 1,
  AMATEUR: 2,
  ROOKIE: 3,
  SEMIPRO: 4,
  PRO: 5,
  ALLSTAR: 6,
}

const RarityUids = {
  BEGINNER: 'beginner',
  AMATEUR: 'amateur',
  ROOKIE: 'rookie',
  SEMIPRO: 'semipro',
  PRO: 'pro',
  ALLSTAR: 'allstar',
}

const getRarityLevelFromUid = {
  [RarityUids.BEGINNER]: RarityLevels.BEGINNER,
  [RarityUids.AMATEUR]: RarityLevels.AMATEUR,
  [RarityUids.ROOKIE]: RarityLevels.ROOKIE,
  [RarityUids.SEMIPRO]: RarityLevels.SEMIPRO,
  [RarityUids.PRO]: RarityLevels.PRO,
  [RarityUids.ALLSTAR]: RarityLevels.ALLSTAR,
}

const CreateShoe = ({ existingItems }) => {
  const [name, setName] = useState()
  const [uid, setUid] = useState()
  const [image, setImage] = useState()
  const [rarity, setRarity] = useState('beginner')
  const [description, setDescription] = useState()
  const [message, setMessage] = useState()
  const [showImageSelector, setShowImageSelector] = useState(false)
  const [loading, setLoading] = useState(false)

  const rarities = Object.values(RarityUids)
  const usedImageNames = existingItems.map((item) => item.image)
  const availableShoeImages = Object.keys(images)
    .filter((imageName) => !usedImageNames.includes(imageName))
    .map((name) => ({ name: name, image: loadItem(name) }))

  const createShoePressed = () => {
    if (loading) return
    setLoading(true)
    createShoe(uid, name, image.name, description, rarity)
      .then(() => {
        setLoading(false)
        setName('')
        setUid('')
        setRarity('beginner')
        setImage(undefined)
        setDescription('')
      })
      .catch((error) => {
        setMessage({ text: error.message, type: 'danger' })
        setLoading(false)
      })
  }

  return (
    <Card className="createShoeCard card">
      {showImageSelector && (
        <ShoeImageSelectorModal
          onClose={() => setShowImageSelector(false)}
          shoes={availableShoeImages}
          onSelect={(item) => {
            setImage(item)
            setShowImageSelector(false)
          }}
        />
      )}
      <Card.Header as="h5">Create Shoe</Card.Header>
      <Card.Body className="content">
        <Form className="form shoeForm">
          <Form.Group>
            <img
              src={image && image.image ? image.image : ''}
              alt="shoe"
              className="smallShoeImage"
            />
          </Form.Group>
          <Form.Group>
            <Button onClick={() => setShowImageSelector(true)}>
              Select an Image
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Shoe UID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a shoe unique identifier (no spaces)"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Shoe Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a shoe name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Rarity</Form.Label>
            <Form.Select
              value={rarity}
              onChange={(e) => setRarity(e.target.value)}
            >
              {rarities.map((r) => (
                <option key={r} value={r}>
                  {r + ' - ' + getRarityLevelFromUid[r].toString()}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="buttonContainer">
            <Button variant="success" onClick={createShoePressed}>
              Create Shoe
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  )
}

export default CreateShoe
