import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, doc } from 'firebase/firestore'
import GameStatTile from '../util/GameStatTile'
import '../../css/GameStats.css'

const MAX_POINTS_PER_LEVEL = 50

export const getDifficulty = (level, points) => {
  return (
    (level - 1) * MAX_POINTS_PER_LEVEL + Math.min(points, MAX_POINTS_PER_LEVEL)
  )
}

const GameStats = ({ userId }) => {
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const q = query(doc(db, 'gameStats', userId))
    const unsubscribe = onSnapshot(q, (doc) => {
      setUserData(doc.data())
    })
    return () => {
      unsubscribe()
    }
  }, [userId])

  if (!userData) return <></>

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {Object.keys(userData).map((key) => (
        <div className="bg-white rounded-md p-4 shadow-sm relative overflow-hidden flex flex-col justify-center">
          <h4 className="text-sm font-medium text-gray-900 capitalize">
            {key}
          </h4>

          <div>
            <dt className="text-sm  text-gray-500">
              <span className="font-medium text-gray-500">
                {getDifficulty(userData[key].level, userData[key].points)}
              </span>{' '}
              <span className="font-normal">difficulty</span>
            </dt>
          </div>

          <div>
            <dt className="text-sm  text-gray-500">
              <span className="font-medium text-gray-500">
                {userData[key]?.gamesPlayed}
              </span>{' '}
              <span className="font-normal">games played</span>
            </dt>
          </div>

          <div>
            <dt className="text-sm  text-gray-500">
              <span className="font-medium">{userData[key]?.highScore}</span>{' '}
              <span className="font-normal">points earned</span>
            </dt>
          </div>
        </div>
      ))}
    </div>
  )
}

export default GameStats
