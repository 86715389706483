import React from "react";
import Button from "react-bootstrap/Button";
import "../../css/Links.css";

const Links = () => {
  return (
    <div className="linkContainer">
      <Button
        as="a"
        href="https://webflow.com/dashboard?workspace=velocitys-workspace-b24dd8"
        target="_blank"
        className="linkItem btn-lg"
        variant="success"
      >
        Webflow
      </Button>
      <Button
        as="a"
        href="https://dashboard.storyly.io/home"
        target="_blank"
        className="linkItem btn-lg"
        variant="danger"
      >
        Storyly
      </Button>
      <Button
        as="a"
        href="https://analytics.google.com/analytics/web/"
        target="_blank"
        className="linkItem btn-lg"
        variant="warning"
      >
        Google Analytics
      </Button>
      <Button
        as="a"
        href="https://appstoreconnect.apple.com/apps/1641576004/appstore/ios/version/inflight"
        target="_blank"
        className="linkItem btn-lg"
        variant="secondary"
      >
        TestFlight
      </Button>
      <Button
        as="a"
        href="https://console.firebase.google.com/u/7/project/velocity-v1/overview"
        target="_blank"
        className="linkItem btn-lg"
        variant="info"
      >
        Firebase Console
      </Button>
      <Button
        as="a"
        href="https://console.cloud.google.com/welcome?project=velocity-v1"
        target="_blank"
        className="linkItem btn-lg"
        variant="dark"
      >
        Google Cloud Platform
      </Button>
      <Button
        as="a"
        href="https://airtable.com/app4jc36rhHRFeLZh/tblQRPWAMHuxI1oFN/viwZqgk4lXhKVKEQ8?blocks=hide"
        target="_blank"
        className="linkItem btn-lg"
        variant="primary"
      >
        Airtable
      </Button>
      <Button
        as="a"
        href="https://app.asana.com/"
        target="_blank"
        className="linkItem btn-lg"
        variant="success"
      >
        Asana
      </Button>
    </div>
  );
};

export default Links;
