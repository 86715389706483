import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "../../css/CreateShoe.css";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { db } from "../../services/firebaseConfig";
import { query, onSnapshot, doc } from "firebase/firestore";
import "../../css/CreateAchievement.css";
import { loadItem } from "../../assets";
import ShoeImageSelectorModal from "../util/ShoeImageSelectorModal";
import { createAchievement } from "../../api/achievements";
import Spinner from "react-bootstrap/Spinner";

const categories = [
  "memory",
  "attention",
  "decision",
  "vision",
  "space",
  "xfactor",
];

const Triggers = {
  GAME_SCORE: "gameScore",
  CATEGORY_SCORE: "categoryScore",
  GAME_PLAYED: "gamePlayed",
  OVERALL_SCORE: "overallScore",
};

const variables = {
  [Triggers.GAME_SCORE]: ["score", "points", "difficulty"],
  [Triggers.CATEGORY_SCORE]: ["score", "points", "level"],
  [Triggers.GAME_PLAYED]: ["gamesPlayed"],
  [Triggers.OVERALL_SCORE]: ["score"],
};

const operators = ["<", ">", "<=", ">=", "==", "!="];

const CreateAchievement = ({ shoes }) => {
  const [name, setName] = useState();
  const [uid, setUid] = useState();
  const [description, setDescription] = useState();
  const [gameUid, setGameUid] = useState();
  const [categoryUid, setCategoryUid] = useState();
  const [trigger, setTrigger] = useState(Triggers.GAME_SCORE);
  const [message, setMessage] = useState();
  const [condition, setCondition] = useState({});
  const [shoe, setShoe] = useState();
  const [games, setGames] = useState([]);
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [loading, setLoading] = useState(false);

  const shoesWithImages = shoes.map((shoe) => ({
    ...shoe,
    image: loadItem(shoe.image),
  }));

  useEffect(() => {
    const gamesQ = query(doc(db, "gameInformation", "gameNames"));
    const unsubscribeGames = onSnapshot(gamesQ, (doc) => {
      const games = [];
      doc.data().games.forEach((name) => {
        games.push(name);
      });
      setGames(games);
    });

    return () => {
      unsubscribeGames();
    };
  }, []);

  const onCreateAchievementPressed = () => {
    if (loading) return;
    if (!uid || !name || !shoe || !description) {
      window.alert("Please fill out all of the required fields.");
      return;
    }
    setLoading(true);
    createAchievement(
      uid,
      name,
      shoe ? shoe.uid : undefined,
      description,
      trigger,
      gameUid,
      categoryUid,
      condition
        ? [
            [
              condition.variable ? condition.variable : "",
              condition.operator ? condition.operator : "",
              condition.value ? parseInt(condition.value) : -1,
            ],
          ]
        : []
    )
      .then(() => {
        setLoading(false);
        setDescription("");
        setName("");
        setShoe({});
        setUid("");
        setTrigger(Triggers.GAME_SCORE);
        setMessage({ text: "Achievement Created!", type: "success" });
      })
      .catch((error) => {
        setLoading(false);
        setMessage({ text: error.message, type: "danger" });
      });
  };

  return (
    <Card className="createShoeCard card">
      {showImageSelector && (
        <ShoeImageSelectorModal
          onClose={() => setShowImageSelector(false)}
          shoes={shoesWithImages}
          onSelect={(item) => {
            setShoe(item);
            setShowImageSelector(false);
          }}
        />
      )}
      <Card.Header as="h5">Create Achievement</Card.Header>
      <Card.Body className="content">
        <Form className="form shoeForm">
          <Form.Group>
            <img
              src={shoe ? shoe.image : ""}
              alt="shoe"
              className="smallShoeImage"
            />
          </Form.Group>
          <Form.Group>
            <Button
              onClick={() => {
                setShowImageSelector(true);
              }}
            >
              Select a Shoe To Unlock
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>Achievement UID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter an achievement unique identifier (no spaces)"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Achievement Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter an achievement name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Trigger</Form.Label>
            <Form.Select
              value={trigger}
              onChange={(e) => setTrigger(e.target.value)}
            >
              {Object.values(Triggers).map((trigger) => (
                <option key={trigger} value={trigger}>
                  {trigger}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {(trigger === Triggers.GAME_PLAYED ||
            (trigger === Triggers.GAME_SCORE && !categoryUid)) && (
            <Form.Group>
              <Form.Label>Game UID</Form.Label>
              <Form.Select
                value={gameUid}
                onChange={(e) => setGameUid(e.target.value)}
                defaultValue="select a game uid"
              >
                <option value={undefined}>None</option>
                {games.map((game) => (
                  <option key={game} value={game}>
                    {game}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {(trigger === Triggers.GAME_PLAYED ||
            (trigger === Triggers.CATEGORY_SCORE && !gameUid)) && (
            <Form.Group>
              <Form.Label>Category UID</Form.Label>
              <Form.Select
                value={categoryUid}
                onChange={(e) => setCategoryUid(e.target.value)}
              >
                <option value={undefined}>None</option>
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Conditions</Form.Label>
            <div className="conditions">
              <Form.Select
                value={
                  condition && condition.variable
                    ? condition.variable
                    : undefined
                }
                onChange={(e) =>
                  setCondition(
                    condition
                      ? { ...condition, variable: e.target.value }
                      : { variable: e.target.value }
                  )
                }
              >
                <option value={undefined}>Select Variable</option>
                {variables[trigger].map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={
                  condition && condition.operator
                    ? condition.operator
                    : undefined
                }
                onChange={(e) =>
                  setCondition(
                    condition
                      ? { ...condition, operator: e.target.value }
                      : { operator: e.target.value }
                  )
                }
              >
                <option value={undefined}>Select Operator</option>
                {operators.map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </Form.Select>
              <Form.Control
                type="number"
                placeholder="Value"
                value={
                  condition && condition.value ? condition.value : undefined
                }
                onChange={(e) =>
                  setCondition(
                    condition
                      ? { ...condition, value: e.target.value }
                      : { value: e.target.value }
                  )
                }
              />
            </div>
          </Form.Group>
          <Form.Group className="buttonContainer">
            <Button variant="success" onClick={onCreateAchievementPressed}>
              Create Achievement
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {loading && <Spinner animation="border" />}
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  );
};

export default CreateAchievement;
