const style1_1 = require("./shoes/style1/style1-1-no-shadow.png");
const style1_2 = require("./shoes/style1/style1-2-no-shadow.png");
const style1_3 = require("./shoes/style1/style1-3-no-shadow.png");
const style1_4 = require("./shoes/style1/style1-4-no-shadow.png");
const style1_5 = require("./shoes/style1/style1-5-no-shadow.png");
const style1_6 = require("./shoes/style1/style1-6-no-shadow.png");
const style1_7 = require("./shoes/style1/style1-7-no-shadow.png");
const style1_8 = require("./shoes/style1/style1-8-no-shadow.png");
const style1_9 = require("./shoes/style1/style1-9-no-shadow.png");
const style1_10 = require("./shoes/style1/style1-10-no-shadow.png");

const style2_1 = require("./shoes/style2/style2-1-no-shadow.png");
const style2_2 = require("./shoes/style2/style2-2-no-shadow.png");
const style2_3 = require("./shoes/style2/style2-3-no-shadow.png");
const style2_4 = require("./shoes/style2/style2-4-no-shadow.png");
const style2_5 = require("./shoes/style2/style2-5-no-shadow.png");
const style2_6 = require("./shoes/style2/style2-6-no-shadow.png");
const style2_7 = require("./shoes/style2/style2-7-no-shadow.png");
const style2_8 = require("./shoes/style2/style2-8-no-shadow.png");
const style2_9 = require("./shoes/style2/style2-9-no-shadow.png");
const style2_10 = require("./shoes/style2/style2-10-no-shadow.png");
const style2_11 = require("./shoes/style2/style2-11-no-shadow.png");

const style3_1 = require("./shoes/style3/style3-1-no-shadow.png");
const style3_2 = require("./shoes/style3/style3-2-no-shadow.png");
const style3_3 = require("./shoes/style3/style3-3-no-shadow.png");
const style3_4 = require("./shoes/style3/style3-4-no-shadow.png");
const style3_5 = require("./shoes/style3/style3-5-no-shadow.png");
const style3_6 = require("./shoes/style3/style3-6-no-shadow.png");
const style3_7 = require("./shoes/style3/style3-7-no-shadow.png");
const style3_8 = require("./shoes/style3/style3-8-no-shadow.png");
const style3_9 = require("./shoes/style3/style3-9-no-shadow.png");
const style3_10 = require("./shoes/style3/style3-10-no-shadow.png");
const style3_11 = require("./shoes/style3/style3-11-no-shadow.png");

const style4_1 = require("./shoes/style4/style4-1-no-shadow.png");
const style4_2 = require("./shoes/style4/style4-2-no-shadow.png");
const style4_3 = require("./shoes/style4/style4-3-no-shadow.png");
const style4_4 = require("./shoes/style4/style4-4-no-shadow.png");
const style4_5 = require("./shoes/style4/style4-5-no-shadow.png");
const style4_6 = require("./shoes/style4/style4-6-no-shadow.png");
const style4_7 = require("./shoes/style4/style4-7-no-shadow.png");
const style4_8 = require("./shoes/style4/style4-8-no-shadow.png");
const style4_9 = require("./shoes/style4/style4-9-no-shadow.png");
const style4_10 = require("./shoes/style4/style4-10-no-shadow.png");

const style5_1 = require("./shoes/style5/style5-1-no-shadow.png");
const style5_2 = require("./shoes/style5/style5-2-no-shadow.png");
const style5_3 = require("./shoes/style5/style5-3-no-shadow.png");
const style5_4 = require("./shoes/style5/style5-4-no-shadow.png");
const style5_5 = require("./shoes/style5/style5-5-no-shadow.png");
const style5_6 = require("./shoes/style5/style5-6-no-shadow.png");
const style5_7 = require("./shoes/style5/style5-7-no-shadow.png");
const style5_8 = require("./shoes/style5/style5-8-no-shadow.png");
const style5_9 = require("./shoes/style5/style5-9-no-shadow.png");
const style5_10 = require("./shoes/style5/style5-10-no-shadow.png");

const style6_1 = require("./shoes/style6/style6-1-no-shadow.png");
const style6_2 = require("./shoes/style6/style6-2-no-shadow.png");
const style6_3 = require("./shoes/style6/style6-3-no-shadow.png");
const style6_4 = require("./shoes/style6/style6-4-no-shadow.png");
const style6_5 = require("./shoes/style6/style6-5-no-shadow.png");
const style6_6 = require("./shoes/style6/style6-6-no-shadow.png");
const style6_7 = require("./shoes/style6/style6-7-no-shadow.png");
const style6_8 = require("./shoes/style6/style6-8-no-shadow.png");
const style6_9 = require("./shoes/style6/style6-9-no-shadow.png");
const style6_10 = require("./shoes/style6/style6-10-no-shadow.png");

const style7_1 = require("./shoes/style7/style7-1-no-shadow.png");
const style7_2 = require("./shoes/style7/style7-2-no-shadow.png");
const style7_3 = require("./shoes/style7/style7-3-no-shadow.png");
const style7_4 = require("./shoes/style7/style7-4-no-shadow.png");
const style7_5 = require("./shoes/style7/style7-5-no-shadow.png");
const style7_6 = require("./shoes/style7/style7-6-no-shadow.png");
const style7_7 = require("./shoes/style7/style7-7-no-shadow.png");
const style7_8 = require("./shoes/style7/style7-8-no-shadow.png");
const style7_9 = require("./shoes/style7/style7-9-no-shadow.png");
const style7_10 = require("./shoes/style7/style7-10-no-shadow.png");

const style8_1 = require("./shoes/style8/style8-1-no-shadow.png");
const style8_2 = require("./shoes/style8/style8-2-no-shadow.png");
const style8_3 = require("./shoes/style8/style8-3-no-shadow.png");
const style8_4 = require("./shoes/style8/style8-4-no-shadow.png");
const style8_5 = require("./shoes/style8/style8-5-no-shadow.png");
const style8_6 = require("./shoes/style8/style8-6-no-shadow.png");
const style8_7 = require("./shoes/style8/style8-7-no-shadow.png");
const style8_8 = require("./shoes/style8/style8-8-no-shadow.png");
const style8_9 = require("./shoes/style8/style8-9-no-shadow.png");
const style8_10 = require("./shoes/style8/style8-10-no-shadow.png");

const style9_1 = require("./shoes/style9/style9-1-no-shadow.png");
const style9_2 = require("./shoes/style9/style9-2-no-shadow.png");
const style9_3 = require("./shoes/style9/style9-3-no-shadow.png");
const style9_4 = require("./shoes/style9/style9-4-no-shadow.png");
const style9_5 = require("./shoes/style9/style9-5-no-shadow.png");
const style9_6 = require("./shoes/style9/style9-6-no-shadow.png");
const style9_7 = require("./shoes/style9/style9-7-no-shadow.png");
const style9_8 = require("./shoes/style9/style9-8-no-shadow.png");
const style9_9 = require("./shoes/style9/style9-9-no-shadow.png");
const style9_10 = require("./shoes/style9/style9-10-no-shadow.png");

const style10_1 = require("./shoes/style10/style10-1-no-shadow.png");
const style10_2 = require("./shoes/style10/style10-2-no-shadow.png");
const style10_3 = require("./shoes/style10/style10-3-no-shadow.png");
const style10_4 = require("./shoes/style10/style10-4-no-shadow.png");
const style10_5 = require("./shoes/style10/style10-5-no-shadow.png");
const style10_6 = require("./shoes/style10/style10-6-no-shadow.png");
const style10_7 = require("./shoes/style10/style10-7-no-shadow.png");
const style10_8 = require("./shoes/style10/style10-8-no-shadow.png");
const style10_9 = require("./shoes/style10/style10-9-no-shadow.png");
const style10_10 = require("./shoes/style10/style10-10-no-shadow.png");

export const images = {
  ["style1-1"]: style1_1,
  ["style1-2"]: style1_2,
  ["style1-3"]: style1_3,
  ["style1-4"]: style1_4,
  ["style1-5"]: style1_5,
  ["style1-6"]: style1_6,
  ["style1-7"]: style1_7,
  ["style1-8"]: style1_8,
  ["style1-9"]: style1_9,
  ["style1-10"]: style1_10,
  ["style2-1"]: style2_1,
  ["style2-2"]: style2_2,
  ["style2-3"]: style2_3,
  ["style2-4"]: style2_4,
  ["style2-5"]: style2_5,
  ["style2-6"]: style2_6,
  ["style2-7"]: style2_7,
  ["style2-8"]: style2_8,
  ["style2-9"]: style2_9,
  ["style2-10"]: style2_10,
  ["style2-11"]: style2_11,
  ["style3-1"]: style3_1,
  ["style3-2"]: style3_2,
  ["style3-3"]: style3_3,
  ["style3-4"]: style3_4,
  ["style3-5"]: style3_5,
  ["style3-6"]: style3_6,
  ["style3-7"]: style3_7,
  ["style3-8"]: style3_8,
  ["style3-9"]: style3_9,
  ["style3-10"]: style3_10,
  ["style3-11"]: style3_11,
  ["style4-1"]: style4_1,
  ["style4-2"]: style4_2,
  ["style4-3"]: style4_3,
  ["style4-4"]: style4_4,
  ["style4-5"]: style4_5,
  ["style4-6"]: style4_6,
  ["style4-7"]: style4_7,
  ["style4-8"]: style4_8,
  ["style4-9"]: style4_9,
  ["style4-10"]: style4_10,
  ["style5-1"]: style5_1,
  ["style5-2"]: style5_2,
  ["style5-3"]: style5_3,
  ["style5-4"]: style5_4,
  ["style5-5"]: style5_5,
  ["style5-6"]: style5_6,
  ["style5-7"]: style5_7,
  ["style5-8"]: style5_8,
  ["style5-9"]: style5_9,
  ["style5-10"]: style5_10,
  ["style6-1"]: style6_1,
  ["style6-2"]: style6_2,
  ["style6-3"]: style6_3,
  ["style6-4"]: style6_4,
  ["style6-5"]: style6_5,
  ["style6-6"]: style6_6,
  ["style6-7"]: style6_7,
  ["style6-8"]: style6_8,
  ["style6-9"]: style6_9,
  ["style6-10"]: style6_10,
  ["style7-1"]: style7_1,
  ["style7-2"]: style7_2,
  ["style7-3"]: style7_3,
  ["style7-4"]: style7_4,
  ["style7-5"]: style7_5,
  ["style7-6"]: style7_6,
  ["style7-7"]: style7_7,
  ["style7-8"]: style7_8,
  ["style7-9"]: style7_9,
  ["style7-10"]: style7_10,
  ["style8-1"]: style8_1,
  ["style8-2"]: style8_2,
  ["style8-3"]: style8_3,
  ["style8-4"]: style8_4,
  ["style8-5"]: style8_5,
  ["style8-6"]: style8_6,
  ["style8-7"]: style8_7,
  ["style8-8"]: style8_8,
  ["style8-9"]: style8_9,
  ["style8-10"]: style8_10,
  ["style9-1"]: style9_1,
  ["style9-2"]: style9_2,
  ["style9-3"]: style9_3,
  ["style9-4"]: style9_4,
  ["style9-5"]: style9_5,
  ["style9-6"]: style9_6,
  ["style9-7"]: style9_7,
  ["style9-8"]: style9_8,
  ["style9-9"]: style9_9,
  ["style9-10"]: style9_10,
  ["style10-1"]: style10_1,
  ["style10-2"]: style10_2,
  ["style10-3"]: style10_3,
  ["style10-4"]: style10_4,
  ["style10-5"]: style10_5,
  ["style10-6"]: style10_6,
  ["style10-7"]: style10_7,
  ["style10-8"]: style10_8,
  ["style10-9"]: style10_9,
  ["style10-10"]: style10_10,
};

export const loadItem = (imageName) => {
  return images[imageName];
};
