import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, collection } from 'firebase/firestore'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { markFeedbackViewed } from '../../api/feedback'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

export default function Feedback() {
  const [feedback, setFeedback] = useState([])
  const [loading, setLoading] = useState(false)
  const [sortByDate, setSortByDate] = useState('asc')

  useEffect(() => {
    const q = query(collection(db, 'feedback'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const feedback = []
      querySnapshot.forEach((doc) => {
        feedback.push({
          ...doc.data(),
          createdAt: doc.data().createdAt.seconds * 1000,
          id: doc.id,
        })
      })

      feedback.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setFeedback(feedback)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const markViewed = (itemId, checked) => {
    if (loading) return
    setLoading(true)
    markFeedbackViewed(itemId, checked)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Feedback</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the feedback from active Velocity users.
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="group inline-flex">
                        User Id
                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                          <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span className="group inline-flex">
                        Created At
                        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          {sortByDate === 'asc' ? (
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              onClick={() => {
                                setSortByDate('desc')
                                feedback.sort(
                                  (a, b) =>
                                    new Date(a.createdAt) -
                                    new Date(b.createdAt)
                                )
                              }}
                            />
                          ) : (
                            <ChevronUpIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              onClick={() => {
                                setSortByDate('asc')
                                feedback.sort(
                                  (a, b) =>
                                    new Date(b.createdAt) -
                                    new Date(a.createdAt)
                                )
                              }}
                            />
                          )}
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span className="group inline-flex">
                        Category
                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                          <ChevronDownIcon
                            className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <span className="group inline-flex">
                        Feedback
                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                          <ChevronDownIcon
                            className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-900"
                    >
                      <span className="group inline-flex">
                        Viewed
                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"></span>
                      </span>
                      <span className="sr-only">Viewed</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {feedback.map((item, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 align-top">
                        <Link to={`/user/${item.user.id}`}>{item.user.id}</Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
                        {new Date(item.createdAt).toDateString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
                        {item.category}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
                        <p className="max-w-[300px] whitespace-normal">
                          {item.message}
                        </p>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 align-top">
                        <Form>
                          <Form.Check
                            type="switch"
                            checked={
                              item.viewed !== undefined ? item.viewed : false
                            }
                            onChange={(e) => {
                              markViewed(item.id, e.target.checked)
                            }}
                          />
                        </Form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
