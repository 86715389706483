import React, { useEffect, useState } from "react";
import { getDailyGamesPlayed, getWeeklyGamesPlayed } from "../../api/analytics";
import Card from "react-bootstrap/Card";
import "../../css/Analytics.css";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import TopUserGameLogs from "components/lists/TopUserGameLogs";

var randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

const renderBarChart = (data) => (
  <ResponsiveContainer height={500}>
    <PieChart>
      <Tooltip />
      <Legend />
      <Pie data={data} dataKey="value" nameKey="gameUid" cx="50%" cy="50%">
        {data.map((entry, index) => (
          <Cell fill={"#" + randomColor()} />
        ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
);

const Analytics = () => {
  const [dailyScores, setDailyScores] = useState([]);
  const [weeklyScores, setWeeklyScores] = useState([]);
  const [dailyChartData, setDailyChartData] = useState([]);
  const [weeklyChartData, setWeeklyChartData] = useState([]);

  const getChartData = (scores) => {
    const gamesPlayed = {};
    for (const game of scores) {
      if (gamesPlayed[game.gameUid]) gamesPlayed[game.gameUid] += 1;
      else gamesPlayed[game.gameUid] = 1;
    }
    return Object.keys(gamesPlayed).map((key) => ({
      gameUid: key,
      value: gamesPlayed[key],
    }));
  };

  useEffect(() => {
    getDailyGamesPlayed().then((games) => {
      setDailyScores(games);
      setDailyChartData(getChartData(games));
    });
    getWeeklyGamesPlayed().then((games) => {
      setWeeklyScores(games);
      setWeeklyChartData(getChartData(games));
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className="gameInfoContainer">
        <Card className="gameInfoCard">
          <Card.Header as="h5">Games Played In Last 7 Days</Card.Header>
          <Card.Body>
            <Card.Text>
              <strong>Games Played</strong>: {weeklyScores.length}
            </Card.Text>
            {renderBarChart(weeklyChartData)}
          </Card.Body>
        </Card>
        <Card className="gameInfoCard">
          <Card.Header as="h5">Games Played Today</Card.Header>
          <Card.Body>
            <Card.Text>
              <strong>Games Played</strong>: {dailyScores.length}
            </Card.Text>
            {renderBarChart(dailyChartData)}
          </Card.Body>
        </Card>
      </div>
      <TopUserGameLogs />
    </div>
  );
};

export default Analytics;
