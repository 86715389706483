import React, { useEffect, useState } from "react";
import { db } from "../../services/firebaseConfig";
import { query, onSnapshot, collection } from "firebase/firestore";
import "../../css/Home.css";
import InviteCodeTable from "../lists/InviteCodeTable";
import CreateInviteCodeForm from "../forms/CreateInviteCodeForm";
import TeamsList from "../lists/TeamsList";
import CreateTeamForm from "../forms/CreateTeamForm";

const InviteCode = () => {
  const [codes, setCodes] = useState();

  useEffect(() => {
    const q = query(collection(db, "inviteCodes"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const codes = [];
      querySnapshot.forEach((doc) => {
        codes.push({ ...doc.data(), code: doc.id });
      });
      setCodes(codes);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="margin">
        <h3 className="inviteCodeHeader">Invites Codes Created</h3>
        <InviteCodeTable codes={codes} />
        <CreateInviteCodeForm />
        <h3 className="inviteCodeHeader">Teams</h3>
        <TeamsList />
        <CreateTeamForm codes={codes} />
      </div>
    </div>
  );
};

export default InviteCode;
