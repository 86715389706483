import { useState, useEffect, Fragment } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import Login from './components/screens/Login'
import Home from './components/screens/Home'
import { Navigation } from './components/Navigation'
import { auth, logout } from './services/firebaseConfig'
import './css/App.css'
import { addAuthClaims, getUserProfile } from './api/auth'

import icon from './assets/Velocity_Icon_RGB_300ppi.png'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        addAuthClaims()
        getUserProfile(auth.currentUser.uid).then((result) => {
          if (result.appRoles.includes('admin')) setLoggedIn(true)
          else {
            alert('You must be an admin to log in.')
            logout()
          }
        })
      } else setLoggedIn(false)
    })
  }, [])

  return (
    <>
      <Router>
        <div>
          {loggedIn && <Navigation></Navigation>}

          <div
            className={classNames(
              loggedIn ? 'md:pl-64' : '',
              'flex flex-1 flex-col'
            )}
          >
            <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1">
              <div className="app bg-gray-100">
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                  {!loggedIn ? <Login /> : <Home />}
                </div>
              </div>
            </main>
          </div>
        </div>
      </Router>
    </>
  )
}

export default App
