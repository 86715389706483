import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, doc } from 'firebase/firestore'
import clsx from 'clsx'
import CategoryStatTile from '../util/CategoryStatTile'

const Performance = ({ userId }) => {
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const q = query(doc(db, 'performanceStats', userId))
    const unsubscribe = onSnapshot(q, (doc) => {
      setUserData(doc.data())
    })
    return () => {
      unsubscribe()
    }
  }, [userId])

  if (!userData) return <></>

  return (
    <div className="grid sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 flex-grow">
      {Object.keys(userData).map((key) => (
        <div className="bg-white rounded-md p-4 shadow-sm relative overflow-hidden flex flex-col justify-center">
          <div
            className={clsx(
              'h-2 absolute top-0 inset-x-0',
              key === 'memory' ? 'bg-[#0099FF]' : '',
              key === 'decision' ? 'bg-[#5925C8]' : '',
              key === 'attention' ? 'bg-[#AE243D]' : '',
              key === 'vision' ? 'bg-[#24AE5B]' : '',
              key === 'space' ? 'bg-[#AE7724]' : ''
            )}
          ></div>
          <h4 className="text-sm font-medium text-gray-900 capitalize">
            {key}
          </h4>

          <div>
            <dt className="text-sm  text-gray-500">
              <span className="font-medium text-gray-500">
                {userData[key]?.gamesPlayed}
              </span>{' '}
              <span className="font-normal">games played</span>
            </dt>
          </div>

          <div>
            <dt className="text-sm  text-gray-500">
              <span className="font-medium">{userData[key]?.score}</span>{' '}
              <span className="font-normal">points earned</span>
            </dt>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Performance
