import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, doc } from 'firebase/firestore'
import CategoryStatTile from '../util/CategoryStatTile'

const Tournament = ({ userId }) => {
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const q = query(doc(db, 'tournamentStats', userId))
    const unsubscribe = onSnapshot(q, (doc) => {
      setUserData(doc.data())
    })
    return () => {
      unsubscribe()
    }
  }, [userId])

  return (
    <div className="grid xl:grid-cols-4 gap-4">
      <div className="bg-white rounded-md p-4 shadow-sm">
        <div>
          <dt className="text-sm font-medium text-gray-500">Games Played</dt>
          <dd className="text-sm">
            {userData?.gamesPlayed ? userData?.gamesPlayed : 0}
          </dd>
        </div>
        <div>
          <dt className="text-sm font-medium text-gray-500">Score</dt>
          <dd className="text-sm mb-0">
            {userData?.score ? userData?.score : 'N/A'}
          </dd>
        </div>
      </div>
    </div>
  )
}

export default Tournament
