import { db } from "../services/firebaseConfig";
import { getDocs, collection, query, where } from "firebase/firestore";

function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous;
}

export const getNewDailyUsers = async () => {
  const queryDate = getPreviousDay();
  const q = query(collection(db, "users"), where("created", ">=", queryDate));
  const result = await getDocs(q);
  return result;
};

export const getTotalUsers = async () => {
  const q = query(collection(db, "users"));
  const result = await getDocs(q);
  return result.size;
};
