import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import '../../css/Home.css'
import InviteCode from './InviteCode'
import Shoes from './Shoes'
import Feedback from './Feedback'
import Users from './Users'
import User from '../user/User'
import Report from './Report'
import Links from './Links'
import Analytics from './Analytics'
import Seasons from './Seasons'

const Home = () => {
  return (
    <>
      <Routes>
        <Route path="/invite-code" element={<InviteCode />} />
        <Route path="/" element={<Shoes />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/:userId" element={<User />} />
        <Route path="/reports" element={<Report />} />
        <Route path="/links" element={<Links />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/seasons" element={<Seasons />} />
      </Routes>
    </>
  )
}

export default Home
