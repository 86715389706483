import { httpsCallable } from 'firebase/functions'
import { doc, deleteDoc } from 'firebase/firestore'
import { functions, db } from '../services/firebaseConfig'

const createSeasonFunc = httpsCallable(functions, 'createSeason')
const getCurrentSeasonFunc = httpsCallable(functions, 'getCurrentSeason')
const deleteSeasonFunc = httpsCallable(functions, 'deleteSeason')
const createSeasonRewardFunc = httpsCallable(functions, 'createSeasonReward')

export const createSeason = (name, endsAt) => {
  return createSeasonFunc({
    name,
    endsAt,
  })
}

export const deleteSeason = () => {
  return deleteSeasonFunc({})
}

export const getCurrentSeason = () => {
  return getCurrentSeasonFunc({})
    .then((data) => {
      if (Object.keys(data.data).length === 0) return 0
      return data.data.seasonNumber
    })
    .catch(() => 0)
}

export const createSeasonReward = (
  uid,
  name,
  description,
  season,
  rankRequired,
  unlocks
) => {
  return createSeasonRewardFunc({
    uid,
    name,
    description,
    season: parseInt(season),
    rankRequired: parseInt(rankRequired),
    unlocks,
  })
}

export const deleteSeasonReward = async (uid) => {
  return deleteDoc(doc(db, 'seasonRewards', uid))
}
