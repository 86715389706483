import { httpsCallable } from "firebase/functions";
import { functions, db } from "../services/firebaseConfig";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";

const generateInviteCode = httpsCallable(functions, "generateInviteCodes");
const inviteCodeQuantity = httpsCallable(
  functions,
  "increaseInviteCodeQuantity"
);

export const generateCode = (
  userUid,
  code,
  quantity,
  rolesGranted,
  unlocksItems
) => {
  const options = { userId: userUid };

  if (code) {
    options.code = code;
  }
  if (quantity) {
    options.quantity = parseInt(quantity);
  }
  if (rolesGranted) {
    options.rolesGranted = rolesGranted;
  }

  if (unlocksItems) {
    options.unlocksItems = unlocksItems;
  }

  return generateInviteCode(options);
};

export const getUserIdByUsername = async (username) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("username", "==", username));
  const result = await getDocs(q);
  let user = undefined;
  result.forEach((doc) => {
    user = doc.id;
  });
  return user;
};

export const deleteInviteCode = async (code) => {
  return deleteDoc(doc(db, "inviteCodes", code));
};

export const increaseInviteCodeQuantity = (code, quantity) => {
  return inviteCodeQuantity({ codeUid: code, quantity: parseInt(quantity) });
};
