import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { deleteItem } from '../../api/shoes'
import Spinner from 'react-bootstrap/Spinner'
import ItemCard from '../util/ItemCard'
import '../../css/ShoesList.css'

const ShoesList = ({
  items,
  title,
  showDelete,
  onStarterItemPressed,
  showSelectStarterItem,
  onRemoveStarterItemPressed,
  showRemoveStarterItem,
}) => {
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)

  const deleteItemUi = (itemUid) => {
    if (loading) return
    if (window.confirm("Are you sure you'd like to delete this item?")) {
      setLoading(true)
      deleteItem(itemUid)
        .then(() => {
          setMessage({ text: 'Deleted successfully!', type: 'success' })
          setLoading(false)
        })
        .catch((error) => {
          setMessage({ text: error.message, type: 'danger' })
          setLoading(false)
        })
    }
  }

  return (
    <div>
      <div>
        <h2 className="text-lg font-bold text-gray-700">{title}</h2>
        {loading && <Spinner animation="border" />}
      </div>
      {message !== undefined && (
        <Alert
          variant={message.type}
          dismissible
          onClose={() => setMessage(undefined)}
        >
          {message.text}
        </Alert>
      )}
      <div className="mt-3">
        <div className="md:col-span-2 md:mt-0">
          <div className="grid grid-cols-4 gap-3">
            {items.map((item) => (
              <ItemCard
                item={item}
                showDelete={showDelete}
                onStarterItemPressed={onStarterItemPressed}
                showSelectStarterItem={showSelectStarterItem}
                onRemoveStarterItemPressed={onRemoveStarterItemPressed}
                showRemoveStarterItem={showRemoveStarterItem}
                deleteItem={deleteItemUi}
                key={item.uid}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoesList
