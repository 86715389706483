import { httpsCallable } from "firebase/functions";
import { functions } from "../services/firebaseConfig";

const createItemFunc = httpsCallable(functions, "createItem");
const deleteItemFunc = httpsCallable(functions, "deleteItem");
const setStartedShoesFunc = httpsCallable(functions, "setStarterShoes");

export const deleteItem = (itemUid) => {
  return deleteItemFunc({ itemUid: itemUid });
};

export const createShoe = (uid, name, image, description, rarity) => {
  const type = "shoe";

  return createItemFunc({
    uid: uid,
    name: name,
    image: image,
    description: description,
    rarity: rarity,
    type: type,
  });
};

export const setStartedShoes = (shoeUids) => {
  return setStartedShoesFunc({ shoeUids: shoeUids });
};
