import { loadItem } from '../../assets'
import Button from 'react-bootstrap/Button'

const ItemCard = ({
  item,
  showDelete,
  onStarterItemPressed,
  showSelectStarterItem,
  onRemoveStarterItemPressed,
  showRemoveStarterItem,
  deleteItem,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-3 relative" key={item.uid}>
      {showRemoveStarterItem && (
        <div
          onClick={() => onRemoveStarterItemPressed(item)}
          className="text-gray-400 hover:text-gray-900 cursor-pointer absolute top-0 right-0 p-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
      <div className="flex flex-col h-full">
        <div className="">
          <img className="h-[100px]" src={loadItem(item.image)} alt="Shoe" />
        </div>
        <div className="flex flex-col h-full">
          <h5 className="text-base mt-3 mb-0">
            <strong>{item.name}</strong>
          </h5>
          <p className="text-gray-600 text-sm">{item.description}</p>

          <div className="flex space-x-2 mb-2 mt-auto">
            <div className="w-1/2 bg-gray-100 rounded-lg p-2">
              <p className="text-xs uppercase text-gray-500 font-bold m-0 font-mono tracking-widest">
                Rarity
              </p>
              <p className="text-sm m-0 font-medium">{item.rarity}</p>
            </div>

            <div className="w-1/2 bg-gray-100 rounded-lg p-2">
              <p className="text-xs uppercase text-gray-500 font-bold m-0 font-mono tracking-widest">
                Type
              </p>
              <p className="text-sm m-0 font-medium">{item.type}</p>
            </div>
          </div>

          {item.unlockedBy && (
            <div className="w-full bg-gray-100 rounded-lg p-2">
              <div>
                <p className="text-xs uppercase text-gray-500 font-bold m-0 font-mono tracking-widest">
                  Unlocked By Achievement
                </p>
                <p className="text-sm m-0 font-medium">{item.unlockedBy}</p>
              </div>
            </div>
          )}

          {item.inviteCode && (
            <div className="w-full bg-gray-100 rounded-lg p-2">
              <div>
                <p className="text-xs uppercase text-gray-500 font-bold m-0 font-mono tracking-widest">
                  Invite Code
                </p>
                <p className="text-sm m-0 font-medium">{item.inviteCode}</p>
              </div>
            </div>
          )}

          {!item.unlockedBy && showDelete && (
            <div
              className="text-gray-400 hover:text-gray-900 cursor-pointer absolute top-0 right-0 p-3"
              onClick={() => deleteItem(item.uid)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}

          {showSelectStarterItem && (
            <button
              onClick={() => onStarterItemPressed(item)}
              className="!border-green-600 !border rounded-md p-2 text-green-600 border-solid hover:bg-green-600 hover:text-white text-sm"
            >
              Set As Starter item
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ItemCard
