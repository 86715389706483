import React, { useEffect, useState } from 'react'
import { db } from '../../services/firebaseConfig'
import {
  query,
  onSnapshot,
  doc,
  collection,
  where,
  orderBy,
  limit,
} from 'firebase/firestore'
import '../../css/GameLogs.css'
import moment from 'moment'
import Button from 'react-bootstrap/Button'

const GameLogs = ({ userId }) => {
  const [games, setGames] = useState([])
  const [viewLogs, setViewLogs] = useState(false)

  useEffect(() => {
    if (!viewLogs || games.length > 1) return

    const userRef = doc(db, 'users', userId)
    const scoreRef = collection(db, 'score')
    const q = query(
      scoreRef,
      where('user', '==', userRef),
      orderBy('createdAt', 'desc'),
      limit(30)
    )
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const games = []
      snapshot.forEach((doc) => games.push(doc.data()))
      setGames(games)
    })
    return () => {
      unsubscribe()
    }
  }, [userId, viewLogs])

  return (
    <div className="bg-white rounded-md p-4 w-full shadow-sm">
      <div className="divide-y">
        {viewLogs &&
          games.map((game) => (
            <div
              className="flex justify-between items-center py-3"
              key={game.createdAt}
            >
              <div>
                <p className="text-sm mb-0 font-medium">{game.gameUid}</p>
                <p className="text-xs text-gray-500 mb-0">
                  {game.categoryUid} - {game.score}
                </p>
              </div>
              <p className="text-sm mb-0">
                {moment(
                  new Date(game.createdAt.seconds * 1000).toISOString()
                ).format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            </div>
          ))}
      </div>
      <div className="logButton">
        <Button onClick={() => setViewLogs(!viewLogs)}>
          {!viewLogs ? 'View Game Logs' : 'Hide Game Logs'}
        </Button>
      </div>
    </div>
  )
}

export default GameLogs
