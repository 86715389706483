import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ShoeImageSelectorModal from "../util/ShoeImageSelectorModal";
import { loadItem } from "../../assets";
import { createSeasonReward } from "../../api/seasons";

const CreateSeasonRewardForm = ({ currentSeason, shoes }) => {
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [description, setDescription] = useState("");
  const [season, setSeason] = useState("");
  const [requiredRank, setRequiredRank] = useState("");
  const [shoe, setShoe] = useState();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [showImageSelector, setShowImageSelector] = useState(false);

  const shoesWithImages = shoes.map((shoe) => ({
    ...shoe,
    image: loadItem(shoe.image),
  }));

  const onCreatePressed = () => {
    if (loading) return;
    
    setLoading(true);

    createSeasonReward(uid, name, description, season, requiredRank, shoe.uid)
      .catch((error) => {
        setMessage({ text: error.message, type: "danger" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card>
      {showImageSelector && (
        <ShoeImageSelectorModal
          onClose={() => setShowImageSelector(false)}
          shoes={shoesWithImages}
          onSelect={(item) => {
            setShoe(item);
            setShowImageSelector(false);
          }}
        />
      )}
      <Card.Header as="h5">Create Season Reward</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <img
              src={shoe ? shoe.image : ""}
              alt="shoe"
              className="smallShoeImage"
            />
          </Form.Group>
          <Form.Group>
            <Button
              onClick={() => {
                setShowImageSelector(true);
              }}
            >
              Select a Shoe To Unlock
            </Button>
          </Form.Group>
          <Form.Group>
            <Form.Label>UID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a reward unique identifier (no spaces)"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reward Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a reward name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Season</Form.Label>
            <Form.Control
              type="number"
              min={currentSeason}
              value={season}
              onChange={(e) => setSeason(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Rank Required</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={requiredRank}
              onChange={(e) => setRequiredRank(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="buttonContainer">
            <Button variant="success" onClick={onCreatePressed}>
              Create Season Reward
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer>
        {loading && <Spinner animation="border" />}
        {message !== undefined && (
          <Alert
            variant={message.type}
            dismissible
            onClose={() => setMessage(undefined)}
          >
            {message.text}
          </Alert>
        )}
      </Card.Footer>
    </Card>
  );
};

export default CreateSeasonRewardForm;
