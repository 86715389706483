import React, { useEffect, useState } from 'react'
import ShoesList from '../lists/ShoesList'
import { db } from '../../services/firebaseConfig'
import { query, onSnapshot, collection } from 'firebase/firestore'
import CreateShoe from '../forms/CreateShoe'
import Button from 'react-bootstrap/Button'
import '../../css/Shoes.css'
import { setStartedShoes } from '../../api/shoes'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import AchievementList from '../lists/AchievementList'
import CreateAchievement from '../forms/CreateAchievement'
import useShoes from '../../hooks/useShoes'
import CreateShoeSlideOver from '../CreateShoeSlideOver'

const Shoes = () => {
  const itemData = useShoes()
  const [items, setItems] = useState(itemData.items)
  const [starterItems, setStarterItems] = useState(itemData.starterItems)
  const [unusedItems, setUnusedItems] = useState(itemData.unusedItems)
  const [inviteCodeItems, setInviteCodeItems] = useState(
    itemData.inviteCodeItems
  )
  const [starterItemsChanged, setStarterItemsChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()
  const [achievements, setAchievements] = useState([])
  const [createShoeSlideOverOpen, setCreateShoeSlideOverOpen] = useState(false)

  useEffect(() => {
    if (starterItemsChanged) return
    setItems(itemData.items)
    setStarterItems(itemData.starterItems)
    setUnusedItems(itemData.unusedItems)
    setInviteCodeItems(itemData.inviteCodeItems)
  }, [itemData, starterItemsChanged])

  useEffect(() => {
    const achievementsQ = query(collection(db, 'achievements'))
    const unsubscribeAchievements = onSnapshot(
      achievementsQ,
      (querySnapshot) => {
        const achievements = []
        querySnapshot.forEach((doc) => {
          achievements.push(doc.data())
        })
        setAchievements(achievements)
      }
    )

    return () => {
      unsubscribeAchievements()
    }
  }, [])

  const saveStarterItems = () => {
    if (loading) return
    setLoading(true)
    setStartedShoes(starterItems.map((item) => item.uid))
      .then(() => {
        setStarterItemsChanged(false)
        setLoading(false)
        setMessage({ text: 'Updated started items!', type: 'success' })
      })
      .catch((error) => {
        setLoading(false)
        setStarterItemsChanged(false)
        setMessage({ text: error.message, type: 'danger' })
      })
  }

  return (
    <div className="container-fluid">
      <div className="flex justify-end">
        <button
          onClick={() => setCreateShoeSlideOverOpen(!createShoeSlideOverOpen)}
          className="mt-2 w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 text-sm leading-6 font-medium rounded-full transition duration-75 ease-in-out md:py-4 md:px-10  tracking-wide focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow bg-gradient-to-br from-cyan-500 via-blue-600 to-blue-600 !text-blue-100 hover:!text-white"
        >
          Make Shoe
        </button>
      </div>
      <ShoesList
        items={items.filter((item) => item.unlockedBy)}
        title={'Shoes With Achievements'}
        showDelete={false}
      />
      <br />
      <ShoesList
        items={starterItems}
        title={
          !starterItemsChanged
            ? '3 Starter Items'
            : '3 Starter Items (Unsaved Changes*)'
        }
        showDelete={false}
        showRemoveStarterItem={true}
        onRemoveStarterItemPressed={(item) => {
          setStarterItemsChanged(true)
          setStarterItems(
            starterItems.filter((existingItem) => existingItem.uid !== item.uid)
          )
          unusedItems.push(item)
        }}
      />
      <br />
      <ShoesList
        items={inviteCodeItems}
        title={'Invite Code Items'}
        showDelete={false}
      />
      <br />
      {starterItemsChanged && (
        <div className="saveButton">
          <Button
            className="btn-success btn-lg"
            disabled={starterItems.length !== 3}
            onClick={saveStarterItems}
          >
            Save Starter Shoes
          </Button>
          {starterItems.length !== 3 && <p>Must select 3 shoes.</p>}
          {loading && <Spinner animation="border" />}
        </div>
      )}
      {message !== undefined && (
        <Alert
          variant={message.type}
          dismissible
          onClose={() => setMessage(undefined)}
        >
          {message.text}
        </Alert>
      )}
      <br />
      {unusedItems.length > 0 && (
        <ShoesList
          items={unusedItems}
          title={'Unassigned Items'}
          showDelete={true}
          showSelectStarterItem={true}
          onStarterItemPressed={(item) => {
            if (starterItems.length >= 3) {
              window.alert('You can only select 3 starter items.')
              return
            }
            if (!starterItems.includes(item)) {
              setStarterItems([...starterItems, item])
              setUnusedItems(
                unusedItems.filter(
                  (existingItem) => existingItem.uid !== item.uid
                )
              )
              setStarterItemsChanged(true)
            }
          }}
        />
      )}
      <br />
      <CreateShoeSlideOver
        open={createShoeSlideOverOpen}
        close={() => {
          setCreateShoeSlideOverOpen(false)
        }}
        existingItems={items}
      />
      <CreateShoe existingItems={items} />
      <br />
      <AchievementList achievements={achievements} shoes={items} />
      <br />
      <CreateAchievement shoes={unusedItems} />
    </div>
  )
}

export default Shoes
